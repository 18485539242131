/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.value-higher{
  color: green;
}

.value-lower{
  color: red;
}

.value-equal{
  color: black;
}

.row-empty{
  color: #c1c1c1;
}
.column-selected  input{
  background-color: #c1c1c1;
}

.row-selected input{
  background-color: #c1c1c1;
}